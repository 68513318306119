<template>
	<TwoColumns
		columns-order="flex-col lg:flex-row"
		first-column-class="items-center flex"
		second-column-class="contained-section"
	>
		<template #first>
			<CarbarImage :image="image" alt-text="Start Subscription" />
		</template>
		<template #second>
			<div class="flex w-full flex-col items-center">
				<div
					v-if="!isRegistered"
					id="start-subscription-journey"
					class="text-gray-575"
				>
					<h2>{{ heading }}</h2>
					<div class="space-y-5 text-lg">
						<p>It takes a few seconds and costs nothing to join.</p>
						<p>
							Compare cars, find out more and have the option to
							start a flexible car subscription.
						</p>
					</div>
					<form @submit="onSubmit">
						<div class="mt-8 space-y-2">
							<InputField
								v-model="firstName"
								name="first_name"
								label="First name"
								class=""
								:error="errors.first_name"
							/>
							<InputField
								v-model="email"
								name="email"
								label="Email"
								class=""
								:error="errors.email"
							/>
							<CarbarButton
								type="submit"
								:disabled="!meta.valid || isSubmitting"
								:show-loader="isSubmitting"
								:is-full-width="false"
							>
								Join now for free
							</CarbarButton>
						</div>
					</form>

					<div class="mt-8 text-xsm">
						By clicking "Join now for free", you acknowledge that
						you have read and agree to carbar’s
						<CarbarLink
							:href="'/terms-and-conditions'"
							:font-size="'text-xsm'"
							text="terms & conditions"
							target="_black"
						/>
						and
						<CarbarLink
							:href="'/privacy-policy'"
							:font-size="'text-xsm'"
							text="privacy policy"
							target="_black"
						/>
						<span>.</span>
					</div>
				</div>
				<div v-if="isRegistered" class="flex flex-col justify-center">
					<div class="text text-2xl text-gray-700">Thank you!</div>
					<div class="mt-5 text-lg text-gray-575">
						We’ve sent an email to your email address with a link to
						activate your account. Please check your email to
						complete signing up.
					</div>
				</div>
			</div>
		</template>
	</TwoColumns>
</template>

<script setup>
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'
import TwoColumns from '@/components/sections/TwoColumns.vue'
import CarbarButton from '@/components/buttons/CarbarButton.vue'
import InputField from '@/components/inputs/InputField.vue'
import CarbarLink from '@/components/links/CarbarLink.vue'
import CarbarImage from '@/components/CarbarImage.vue'

const props = defineProps({
	image: {
		type: String,
		required: false,
		default: 'img/home/img-start_a_journey.webp',
	},
	heading: {
		type: String,
		default: 'Start your carbar journey here',
		required: false,
	},
})

const isRegistered = ref(false)

const { handleSubmit, isSubmitting, errors, setErrors, meta } = useForm({
	initialValues: {
		email: '',
	},
	validationSchema: object({
		email: string('Email must be a string')
			.trim()
			.email('Enter a valid email')
			.required('Email is required')
			.label('Email'),
		first_name: string('First name must be a string')
			.required('First name is required')
			.label('First name'),
	}),
})

const { value: email } = useField('email')
const { value: firstName } = useField('first_name')

const onSubmit = handleSubmit(async (values) => {
	try {
		await useFetchForApi('/register', {
			method: 'POST',
			body: { ...values, type: 'auto' },
		})
		isRegistered.value = true
	} catch (error) {
		setErrors(error.errors)
	}
})
</script>
